import axios from "axios";
import classNames from "classnames";
import React, {
  ChangeEvent,
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { toast, ToastContainer } from "react-toastify";
import images from "../../../constants/images";
import { TCampaignValue } from "../../../pages/dashboard/CampaignCreate";
import { Checkbox } from "../../Checkbox/Checkbox";
import Divider from "../../common/Divider";
import NumberInput from "../../common/NumberInput";
import { ProductCard } from "../../common/ProductCard";
import { ProductQuickView } from "../../common/ProductQuickView";
import CharityModalCampaign from "../CharityModalCampaign";
import ProdutDetailsModalCampaign from "../ProdutDetailsModalCampaign";
import RemoveCollectionModalCampaign from "../RemoveCollectionModalCampaign";
import "./styles.css";

const SELECTED_PRODUCT_LIMIT = 10;

type propType = {
  selectedProduct: number;
  setSelectedProduct: Dispatch<SetStateAction<number>>;
  campaignNameRef: RefObject<HTMLInputElement>;
  amountWithoutFee: number;
  setCampaignValue: React.Dispatch<React.SetStateAction<TCampaignValue>>;
  campaignValue: TCampaignValue;
};

const CampaignDetailsProgress = (props: propType) => {
  const {
    selectedProduct,
    setSelectedProduct,
    campaignNameRef,
    amountWithoutFee,
    setCampaignValue,
    campaignValue,
  } = props;

  const { campaignName, otherAmount, charityToggle, charityList } =
    campaignValue;

  const [quickViewModalVisible, setQuickViewModalVisible] = useState(false);
  const [removeCollectionModalVisible, setRemoveCollectionModalVisible] =
    useState(false);
  const [charityModalVisible, setCharityModalVisible] = useState(false);
  const [selectedQuickViewProduct, setSelectedQuickViewProduct] = useState(0);
  const [otherAmountWithDollar, setotherAmountWithDollar] = useState("");
  const [themes, setThemes] = useState<Theme[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedProductDetail, setSelectedProductDetail] = useState<
    any | null
  >(null);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [offset, setOffset] = useState(0);
  const [highestPrice, setHighestPrice] = useState(0);
  const [selectedTheme, setSelectedTheme] = useState<number | null>(null);
  const [errorMessages, setErrorMessages] = useState<string>("");
  const [firstTimeOnPage, setFirstTimeOnPage] = useState(true);
  const [allSelectedProducts, setAllSelectedProducts] = useState<any[]>([]);

  const charitySectionRef = useRef<HTMLDivElement>(null);

  const [selectAll, setSelectAll] = useState(false);
  const selectedProductIds = campaignValue.productIds;

  const handleSelectAll = () => {
    const budget = parseFloat(campaignValue.otherAmount);
    if (isNaN(budget)) {
      setErrorMessages(`Set a budget!`);
      window.scrollTo(0, 0);
      return;
    }
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    if (newSelectAll) {
      const allProductIds = filteredProducts
        .filter((product) => product.listPrice <= budget)
        .map((product) => product.id);

      setCampaignValue((prev) => ({
        ...prev,
        productIds: allProductIds,
      }));
    } else {
      setCampaignValue((prev) => ({
        ...prev,
        productIds: [],
      }));
    }
  };

  useEffect(() => {
    if (otherAmount !== "") {
      setotherAmountWithDollar("$" + otherAmount);
    } else {
      setotherAmountWithDollar("");
    }
  }, [otherAmount]);

  useEffect(() => {
    if (!charityToggle) {
      const data = { ...campaignValue };
      data["charityList"] = data?.charityList?.map(
        (c: GetAllCharitiesData) => ({ ...c, checked: false })
      );
      setCampaignValue(data);
    }
  }, [charityToggle]);

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_GIFTING_URL}product/themes`
        );
        const themes = response.data.data.themes;
        setThemes(themes);
        setIsLoading(false);

        if (themes.length > 0) {
          const firstThemeId = themes[0].id;
          const firstThemeName = themes[0].name;
          setSelectedTheme(firstThemeId);
          setCampaignValue((prev) => ({
            ...prev,
            selectedTheme: firstThemeId,
            selectedThemeName: firstThemeName,
          }));
          fetchProducts(firstThemeId, 0);
        }
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchThemes();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      )
        return;
      if (filteredProducts.length < totalProducts) {
        fetchMoreProducts();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [filteredProducts, totalProducts]);
  const fetchProducts = async (
    themeId: number,
    offset: number,
    budget: number | null = null
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_GIFTING_URL}product/themes/${themeId}/products`,
        {
          params: {
            limit: SELECTED_PRODUCT_LIMIT,
            offset: offset,
            budget: budget,
            campaignType: campaignValue.campaignType,
          },
        }
      );

      setHighestPrice(
        (prevHighestPrice) =>
          response.data.data.highestPrice || prevHighestPrice
      );
      if (offset === 0) {
        setFilteredProducts(response.data.data.products);
      } else {
        setFilteredProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.data.products,
        ]);
      }
      setTotalProducts(response.data.data.totalCount);
      setOffset(offset + SELECTED_PRODUCT_LIMIT);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const fetchMoreProducts = async () => {
    if (selectedTheme !== null) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_GIFTING_URL}/product/themes/${selectedTheme}/products`,
          {
            params: {
              limit: SELECTED_PRODUCT_LIMIT,
              offset: offset,
              budget: campaignValue.selectedAmount,
            },
          }
        );
        setFilteredProducts((prevProducts) => [
          ...prevProducts,
          ...response.data.data.products,
        ]);
        setOffset(offset + SELECTED_PRODUCT_LIMIT);
      } catch (error) {
        console.error("Error fetching more products:", error);
      }
    }
  };

  const handleThemeClick = async (themeId: number, themeName: string) => {
    setSelectedTheme(themeId);
    setCampaignValue((prev) => ({
      ...prev,
      selectedTheme: themeId,
      selectedThemeName: themeName,
    }));
    setFilteredProducts([]);
    setOffset(0);
    await fetchProducts(themeId, 0);
  };

  const handleBudgetChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (selectedTheme) {
      const budget = e.target.value;
      setCampaignValue((prev) => ({
        ...prev,
        otherAmount: budget,
      }));

      fetchProducts(selectedTheme, 0, +budget);
    }
  };

  const toggleSelected = (productId: number) => {
    const selectedProduct = filteredProducts.find(
      (product) => product.id === productId
    );
    const budget = parseFloat(campaignValue.otherAmount);
    if (selectedProduct && selectedProduct.listPrice > budget) {
      setErrorMessages(`Product price exceeds the budget of $${budget}`);
      window.scrollTo(0, 0);
      return;
    }

    setCampaignValue((prev) => {
      const newProductIds = prev.productIds.includes(productId)
        ? prev.productIds.filter((id) => id !== productId)
        : [...prev.productIds, productId];

      setSelectAll(newProductIds.length === filteredProducts.length);

      return {
        ...prev,
        productIds: newProductIds,
      };
    });
  };

  useEffect(() => {
    if (filteredProducts.length === 0) {
      return;
    }
    setSelectAll(campaignValue.productIds.length === filteredProducts.length);
  }, [campaignValue.productIds, filteredProducts.length]);

  useEffect(() => {
    setCampaignValue((prev) => ({
      ...prev,
      productIds: selectedProductIds,
    }));
  }, [selectedProductIds]);
  const quickViewProduct = (
    event: React.MouseEvent<HTMLDivElement>,
    productId: number
  ) => {
    event.stopPropagation();
    const product = filteredProducts.find(
      (product) => product.id === productId
    );
    if (product) {
      setSelectedProductDetail(product);
      setShowModal(true);
    }
  };

  const updateCart = (productId: number) => {
    setFirstTimeOnPage(false);
    const selectedProduct = filteredProducts.find(
      (product) => product.id === productId
    );

    const budget = parseFloat(campaignValue.otherAmount);
    if (isNaN(budget)) {
      setErrorMessages(`Set a budget!`);
      window.scrollTo(0, 0);
      return;
    }
    if (selectedProduct && selectedProduct.listPrice > budget) {
      setErrorMessages(`Product price exceeds the budget of $${budget}`);
      window.scrollTo(0, 0);
      return;
    }

    setCampaignValue((prev) => {
      const updatedProductIds = prev.productIds.includes(productId)
        ? prev.productIds.filter((id) => id !== productId)
        : [...prev.productIds, productId];

      return {
        ...prev,
        productIds: updatedProductIds,
      };
    });
  };

  const handleCampaignTypeChange = () => {
    setCampaignValue((prev) => {
      const budget = parseFloat(prev.otherAmount);
      let validProductIds = prev.productIds;
      //handle switch from hamper to gift
      if (prev.campaignType === "Hamper" && budget) {
        validProductIds = prev.productIds.filter((productId) => {
          const product = filteredProducts.find((p) => p.id === productId);
          return product && product.listPrice >= budget * 0.66;
        });
      }

      return {
        ...prev,
        campaignType: prev.campaignType === "Hamper" ? "Gift" : "Hamper",
        productIds: validProductIds,
      };
    });
  };

  useEffect(() => {
    if (selectedTheme !== null) {
      fetchProducts(selectedTheme, 0, parseFloat(campaignValue.otherAmount));
    }
  }, [campaignValue.campaignType]);

  //for charity card
  const toggleCharitySelected = (charityId: number) => {
    const updatedCharityList = charityList.map((charity) =>
      charity.id === charityId
        ? { ...charity, checked: !charity.checked }
        : charity
    );
    setCampaignValue((prev) => ({
      ...prev,
      charityList: updatedCharityList,
    }));
  };

  //for charity checkbox
  const handleCharityToggle = () => {
    setCampaignValue((prev) => ({
      ...prev,
      charityToggle: !prev.charityToggle,
    }));
  };
  useEffect(() => {
    if (charityToggle && charitySectionRef.current) {
      charitySectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [charityToggle]);

  useEffect(() => {
    if (errorMessages) {
      toast.error(errorMessages);
    }
  }, [errorMessages, firstTimeOnPage]);

  return (
    <div className="mt-20 mx-auto w-[75%] mb-6">
      <div className="grid grid-cols-5 bg-lightYellow rounded-2xl p-6">
        <div className="col-span-4 grid grid-flow-row">
          <div className="font-leagueSpartan-500 text-[28px] text-neutrals-900">
            Let's get started!
          </div>
          <div className="font-leagueSpartan-300 text-[18px] text-neutrals-700 ">
            Give your campaign a name (which you can change later)!
          </div>
          <input
            className="bg-white mb-auto  rounded-lg border p-2 text-sm"
            placeholder="Campaign Name"
            value={campaignName}
            ref={campaignNameRef}
            onChange={(e) => {
              let value = e.target.value as string;
              setCampaignValue((prev: TCampaignValue) => ({
                ...prev,
                campaignName: value,
              }));
            }}
          />
        </div>
        <div className=" w-full flex justify-end">
          <img src={images.giftYellow} className="h-32 " alt="" />
        </div>
      </div>
      <div className="my-8">
        <Divider />
      </div>

      <div className="mt-8">
        <div className="mb-1">
          <div className="flex flex-wrap gap-4">
            {campaignValue.productIds.map((productId) => {
              const product = filteredProducts.find((p) => p.id === productId);
              return (
                product && (
                  <div key={product.id} className="relative w-20 h-20">
                    <img
                      src={product.image_1}
                      alt={product.name}
                      className="w-full h-full object-cover rounded"
                    />
                    <button
                      className="absolute -top-2 -right-2 bg-orange-500 text-white rounded-full w-5 h-5 flex items-center justify-center z-30 p-2"
                      onClick={() => toggleSelected(product.id)}
                    >
                      x
                    </button>
                  </div>
                )
              );
            })}
          </div>
          <div className="mt-2 text-right text-sm text-gray-600">
            {campaignValue.productIds.length} gifts added
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        <div>
          <div className="font-leagueSpartan-500 text-[20px] text-neutrals-900 mb-2">
            Gift budget (up to $)
          </div>
          <NumberInput
            value={+campaignValue.otherAmount}
            onChange={handleBudgetChange}
          />
          <div className="flex items-center space-x-2 mt-2">
            <div className="mt-2 font-leagueSpartan-300">
              Learn about excess credits
            </div>

            <img
              src={images.infoButton}
              data-tooltip-target="tooltip-bottom"
              data-tooltip-placement="bottom"
              alt="info"
            />
          </div>
          <div
            id="tooltip-bottom"
            role="tooltip"
            className="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-black bg-white rounded-lg shadow-sm opacity-0 tooltip dark:bg-white border border-gray-400"
          >
            <div className="flex flex-col text-left p-2">
              <p className="mb-2">
                • If your gift budget is $25, we will deduct $25 per recipient
                from your credits when you checkout.
              </p>
              <p className="mb-2">
                • If your recipient selects a gift that is $18, we will return
                $7 excess credits to your wallet.
              </p>
              <p>
                • If gift is not claimed after 30 days, we will return the full
                $25 back into your wallet.
              </p>
            </div>
            <div className="tooltip-arrow" data-popper-arrow></div>
          </div>

          <div className="mt-2">
            <Checkbox
              checked={campaignValue.campaignType === "Hamper"}
              onClick={handleCampaignTypeChange}
              text="Allow recipients to select multiple gifts"
            />
          </div>
          <div>
            <div className=" mt-2 font-leagueSpartan-500 text-[20px] text-neutrals-900 mb-2">
              Themes
            </div>
            <ul>
              {themes.map((theme, i) => (
                <li key={theme.id} className="mb-2">
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="radio"
                      name="theme"
                      className="hidden peer"
                      checked={selectedTheme === theme.id}
                      onChange={() => handleThemeClick(theme.id, theme.name)}
                    />
                    <span
                      className={classNames(
                        "block text-[16px] font-leagueSpartan-400 text-neutrals-700 pl-4",
                        {
                          "font-bold text-orange-500":
                            selectedTheme === theme.id,
                        }
                      )}
                    >
                      {theme.name}
                    </span>
                  </label>
                </li>
              ))}
            </ul>
          </div>
          <div className="mt-4">
            <Checkbox
              text="Donate remaining budget to selected charities"
              className="mr-2"
              checked={charityToggle}
              onClick={handleCharityToggle}
            />
          </div>
        </div>
        <div className="col-span-3">
          <div className="flex items-center mb-4">
            <Checkbox
              checked={selectAll}
              onClick={handleSelectAll}
              text="Select all gifts in theme"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            {filteredProducts.map((product) => (
              <ProductCard
                updateCart={() => updateCart(product.id)}
                isSelected={selectedProductIds.includes(product.id)}
                key={product.id}
                product={product}
                displayQuickViewModal={(e, productId) => {
                  quickViewProduct(e, productId);
                }}
              />
            ))}
          </div>

          {charityToggle && (
            <div>
              <div
                ref={charitySectionRef}
                className="font-leagueSpartan-500 text-[20px] text-neutrals-900 mb-2 mt-4"
              >
                Charities
              </div>
              {/* <div className="bg-[#F4F4F4] rounded-2xl p-6">
                <div className="flex justify-between">
                  <div className="font-leagueSpartan-500 text-[14px] text-neutrals-700 tracking-[0.56px]">
                    LIST OF CHARITIES
                  </div>
                  <button
                    className="flex font-leagueSpartan-400 text-[16px] text-orange tracking-[0.16px]"
                    onClick={() => setCharityModalVisible(true)}
                  >
                    Edit
                    <img src={images.edit} className="ml-4 w-[22px]" alt="" />
                  </button>
                </div>
                <div className="mt-2 font-leagueSpartan-400 text-[18px] text-neutrals-900 w-[80%] overflow-hidden text-ellipsis whitespace-nowrap">
                  {charityList.map((c, i) => {
                    return c.name + (i + 1 === charityList.length ? "" : ",");
                  })}
                </div>
              </div> */}
              <div
                ref={charitySectionRef}
                className={`grid grid-cols-2 md:grid-cols-3 gap-4 mt-4`}
              >
                {charityList.map((charity) => (
                  <div
                    key={charity.id}
                    className={`font-leagueSpartan rounded-lg p-4 flex flex-col border border-gray-300 items-center`}
                  >
                    <img
                      src={charity.pic1}
                      alt={charity.name}
                      className="w-24 h-24 object-contain mb-2"
                    />
                    <div className="text-center">
                      <div
                        className={` ${charity.checked ? "text-black" : ""}`}
                      >
                        {charity.name}
                      </div>
                    </div>
                    <button
                      className={`mt-2 px-4 py-2 rounded  ${
                        charity.checked
                          ? "bg-white text-black border border-black"
                          : "bg-orange-500 text-white"
                      }`}
                      onClick={() => toggleCharitySelected(charity.id)}
                    >
                      {charity.checked ? (
                        <>
                          <span>&#10003;</span> Added
                        </>
                      ) : (
                        "Add"
                      )}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {showModal && selectedProductDetail && (
        <ProductQuickView
          product={selectedProductDetail}
          closeModal={() => setShowModal(false)}
        />
      )}
      {quickViewModalVisible && (
        <ProdutDetailsModalCampaign
          collection={filteredProducts[selectedQuickViewProduct]}
          quickProduct={selectedQuickViewProduct}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          setModalVisible={setQuickViewModalVisible}
          setRemoveCollectionModalVisible={setRemoveCollectionModalVisible}
          amountWithoutFee={amountWithoutFee}
        />
      )}
      {removeCollectionModalVisible && (
        <RemoveCollectionModalCampaign
          setModalVisible={setRemoveCollectionModalVisible}
          setCampaignValue={setCampaignValue}
          campaignValue={campaignValue}
          setQuickViewModalVisible={setQuickViewModalVisible}
          selectedQuickViewProduct={selectedQuickViewProduct}
        />
      )}
      {charityModalVisible && (
        <CharityModalCampaign
          setModalVisible={setCharityModalVisible}
          charityList={charityList}
          setCampaignValue={setCampaignValue}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default CampaignDetailsProgress;
